import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    component: () => import("../views/layout/Layout.vue"),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/Home')
      },
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/PrivacyPolicy')
      },
      {
        path: '/terms-condition',
        name: 'TermsAndCondition',
        component: () => import('../views/TermsAndCondition')
      },
      {
        path: '/refund-and-cancellation',
        name: 'RefundAndCancellation',
        component: () => import('../views/RefundAndCancellation')
      }
    ]
  },
  {
    path: "/:pathMatch(.*)",
    name: "NotFoundComponent",
    component: () => import("../views/errors/NotFoundComponent.vue"),
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  const layoutId = document.getElementById("mainbody");
  if (savedPosition) {
    return savedPosition;
  } else if (layoutId) {
    layoutId.scrollTop = 0;
    return { left: 0, top: 0 };
  } else {
    return { left: 0, top: 0 };
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
});

export default router
